import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { Select, message } from 'antd';
import NumberFormat from 'react-number-format';

import Moment from 'moment';

import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { userAssociatedFacilities, downloadRadSummary } from '../../../common/services/ApiService';

import Axios from '../../../config/axios';

import { toast } from '@rickylandino/react-messages';
import { getRadsMappedToFacilityExams } from '../../../common/services/ReportService';

class RadDetailsReportModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: props.showModal,
            allFacilities: [],
            selectedFacility: '',
            availableBirads: [],
            selectedBirad: '',
            availableRads: [],
            selectedRad: '',
            selectedRads: [],
            availableExamTypes: [],
            selectedExamTypes: [],
            selectedDates: {
                startDate: null,
                endDate: null
            },
            showDetails: false
        }

        this.alive = false;
    }

    componentDidMount() {
        this.alive = true;

        combineLatest(
            userAssociatedFacilities
        ).pipe(takeWhile(() => this.alive)).subscribe(([f]) => {
            if (f?.length > 0) {

                let selectedFacility = '';

                if (f.length === 1) {
                    selectedFacility = f[0].facilityModel.facilityID;
                }

                this.setState({
                    allFacilities: f,
                    selectedFacility,
                    loaded: true
                }, () => {
                        if (f.length === 1) {
                            this.handleFacilityChange(selectedFacility);
                        }
                });
            }
        });
    }

    componentWillUnmount() {
        this.alive = false;
    }

    handleDateChange = (dates, selectedDateRange) => {
        this.setState({
            selectedDateRange,
            dates
        });
    }

    handleFacilityChange = (selectedFacility) => {
        

        getRadsMappedToFacilityExams(selectedFacility).then(data => {
            let rads = [...data];

            if (data?.length === 0) {

                let facility = this.state.allFacilities.find(f => f.facilityModel.facilityID === selectedFacility);
                rads = facility?.availableRads || [];

                if (facility) {
                    var index = rads.findIndex(x => x.radiologistID === facility.outsideRadiologist?.radiologistID);

                    if (index === -1) {
                        rads.push(facility.outsideRadiologist);
                    }
                }
            }
            this.setState({
                selectedFacility,
                availableRads: [...rads],
                availableBirads: this.state.allFacilities.find(f => f.facilityModel.facilityID === selectedFacility)?.availableBirads || [],
                selectedRad: '',
                selectedBirad: '',
                availableExamTypes: this.state.allFacilities.find(f => f.facilityModel.facilityID === selectedFacility)?.availableExamTypes || [],
            });
        });
        
    }

    handleBiradChange = (selectedBirad) => {
        this.setState({
            selectedBirad
        });
    }

    handleRadChange = (selectedRad) => {
        this.setState({
            selectedRad
        });
    }

    handleRadsChange = (selectedRads) => {
        this.setState({
            selectedRads
        });
    }

    handleExamTypesChange = (selectedExamTypes) => {
        this.setState({
            selectedExamTypes
        });
    }

    handleInputChange = () => {
        this.setState({
            showDetails: !this.state.showDetails
        });
    }

    handleClear = () => {
        this.setState({
            selectedFacility: '',
            selectedBirad: '',
            availableBirads: [],
            availableRads: [],
            selectedRad: '',
            selectedRads: [],
            selectedExamTypes: [],
            selectedDates: {
                startDate: null,
                endDate: new Date()
            }, 
            showDetails: false
        })
    }

    handleNewDateChange = (name, date) => {
        this.setState({ selectedDates: { ...this.state.selectedDates, [name]: date } });
    }

    handleSubmit = () => {

        let endDate = this.state.selectedDates.endDate ? Moment(this.state.selectedDates.endDate).format("YYYY-MM-DD") : null;
        let startDate = this.state.selectedDates.startDate ? Moment(this.state.selectedDates.startDate).format("YYYY-MM-DD") : null;

        if (this.state.selectedFacility
             && this.state.selectedRads.length > 0) {
            const q = Axios.defaults.baseURL + "api/RenderRadDetailsAndSummary";

            var form = document.createElement("form");
            form.target = "_blank";
            form.method = "POST";
            form.action = q;
            form.style.display = "none";

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "startDate";
            input.value = startDate;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "endDate";
            input.value = endDate;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "selectedFacility";
            input.value = this.state.selectedFacility;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "selectedRads";
            input.value = this.state.selectedRads;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "selectedBirad";
            input.value = this.state.selectedBirad;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "selectedExamTypes";
            input.value = this.state.selectedExamTypes;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "showDetails";
            input.value = this.state.showDetails;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "ianaTimezone";
            input.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
            form.appendChild(input);

            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        } else {
            toast.error("Please provide at least the facility and a radiologist.");
        }
        
    }

    exportExcel = () => {
        const key = "loading";
        message.loading({ content: 'Exporting Tech Summary...', key, duration: 0 });
        let fileName = "rad-summary_" + Moment().format('YYYYMMDDHm') + '.xlsx';

        if (this.state.showDetails) {
            fileName = "rad-details_" + Moment().format('YYYYMMDDHm') + '.xlsx';
        }

        let radString = "";
        this.state.selectedRads.forEach(rad => {
            radString += rad + ",";
        });

        radString = radString.slice(0, -1);

        let postdata = {
            fileName,
            sDate: this.state.selectedDates.startDate ? Moment(this.state.selectedDates.startDate).format("YYYY-MM-DD") : null,
            eDate: this.state.selectedDates.endDate ? Moment(this.state.selectedDates.endDate).format("YYYY-MM-DD") : null,
            selectedFacility: this.state.selectedFacility,
            selectedRads: [radString],
            selectedBirad: this.state.selectedBirad,
            selectedExamTypes: this.state.selectedExamTypes,
            showDetails: this.state.showDetails,
            ianaTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }

        downloadRadSummary(postdata).then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = fileName;//"File.xlsx";
            //document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);

            this.setState({
                downloading: false
            })
        }).catch(error => {
            console.log("Error");
        });
    }
    

    render() {

        const { Option } = Select;

        const { RangePicker } = DatePicker;

        const theme = window.sessionStorage.getItem("theme");

        return (
            <Modal size='lg' show={this.state.showModal} onHide={this.toggleModal} backdrop='static' dialogClassName={theme === 'dark' ? "dark-theme" : 'light-theme'}>
                <Modal.Header>
                    <h1>Radiologist Details and Summary</h1>
                </Modal.Header>
                <Modal.Body>
                    <div className="panel-content">
                        {this.state.loaded &&
                            <div className="row">

                            <div className="form-group col col-6">
                                <div className="form-label">Start Range</div>
                                <DatePicker
                                    onChange={(date) => this.handleNewDateChange('startDate', date)}
                                    selectsStart
                                    startDate={this.state.selectedDates.startDate}
                                    endDate={this.state.selectedDates.endDate}
                                    className="form-control-custom"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText='Start Date'
                                    selected={this.state.selectedDates.startDate && Moment(this.state.selectedDates.startDate).toDate()}
                                    customInput={
                                        <NumberFormat format="##/##/####" mask="_" />
                                    }
                                />
                            </div>
                            <div className="col col-6">
                                <div className="form-label">End Range</div>
                                <DatePicker
                                    onChange={(date) => this.handleNewDateChange('endDate', date)}
                                    selectsEnd
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    startDate={this.state.selectedDates.startDate}
                                    endDate={this.state.selectedDates.endDate}
                                    className="form-control-custom"
                                    placeholderText='End Date'
                                    selected={this.state.selectedDates.endDate && Moment(this.state.selectedDates.endDate).toDate()}
                                    customInput={
                                        <NumberFormat format="##/##/####" mask="_" />
                                    }
                                />

                            </div>

                            <div className="form-group col col-lg-6 col-12">
                                <div className="form-label">Facility <span className="color-pink">*</span></div>
                                {this.state.allFacilities.length === 1 ?
                                    <div>{this.state.allFacilities[0].facilityModel.facilityName}</div>
                                    :
                                    <Select
                                        allowClear
                                        placeholder="Please select"
                                        onChange={this.handleFacilityChange}
                                        className="form-control-custom w-100"
                                        bordered={false}
                                        value={this.state.selectedFacility}
                                        showSearch
                                        virtual={false}
                                        filterOption={(input, option) => (option.children[0] + option.children[1]).toLowerCase().includes(input.toLowerCase())}
                                    >
                                        {this.state.allFacilities.map((fac, idx) => <Option key={idx} key={fac.facilityModel.facilityID} value={fac.facilityModel.facilityID}>{fac.facilityModel.facilityNumber && fac.facilityModel.facilityNumber + " - "}{fac.facilityModel.facilityName}</Option>)}
                                    </Select>
                                }

                            </div>

                            {/*    <div className="form-group col col-lg-6 col-12">*/}
                            {/*    <div className="form-label">Facility <span className="color-pink">*</span></div>*/}
                            {/*        <Select*/}
                            {/*            allowClear*/}
                            {/*            placeholder="Please select"*/}
                            {/*            onChange={this.handleFacilityChange}*/}
                            {/*            className="form-control-custom w-100"*/}
                            {/*        bordered={false}*/}
                            {/*        value={this.state.selectedFacility}*/}
                            {/*        showSearch*/}
                            {/*        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}*/}
                            {/*        >*/}
                            {/*        {this.state.allFacilities.map((fac, idx) => <Option key={idx} key={fac.facilityModel.facilityID} value={fac.facilityModel.facilityID}>{fac.facilityModel.facilityNumber && fac.facilityModel.facilityNumber + " - "}{fac.facilityModel.facilityName}</Option>)}*/}
                            {/*        </Select>*/}
                            {/*</div>*/}

                            {/*<div className="form-group col col-lg-6 col-12">*/}
                            {/*    <div className="form-label">Radiologist <span className="color-pink">*</span></div>*/}
                            {/*    <Select*/}
                            {/*        allowClear*/}
                            {/*        placeholder="Please select"*/}
                            {/*        onChange={this.handleRadChange}*/}
                            {/*        className="form-control-custom w-100"*/}
                            {/*        bordered={false}*/}
                            {/*        value={this.state.selectedRad}*/}
                            {/*    >*/}
                            {/*        {this.state.availableRads.map((r, idx) => <Option key={r.radiologistID} value={r.radiologistID}>{r.fName} {r.lName}</Option>)}*/}
                            {/*    </Select>*/}
                            {/*</div>*/}

                            <div className="form-group col col-lg-6 col-12">
                                <div className="form-label">Radiologist <span className="color-pink">*</span></div>
                                <Select
                                    mode="multiple"
                                    maxTagCount={1}
                                    allowClear
                                    placeholder="Please select"
                                    onChange={this.handleRadsChange}
                                    className="form-control-custom w-100"
                                    bordered={false}
                                    virtual={false}
                                    value={this.state.selectedRads}
                                >
                                    {this.state.availableRads.map((r, idx) => <Option key={r.radiologistID} value={r.radiologistID}>{r.fName} {r.lName}</Option>)}
                                </Select>
                            </div>

                            <div className="form-group col col-lg-6 col-12">
                                <div className="form-label">BIRADS</div>
                                <Select
                                    allowClear
                                    placeholder="Please select"
                                    onChange={this.handleBiradChange}
                                    className="form-control-custom w-100"
                                    bordered={false}
                                    virtual={false}
                                    value={this.state.selectedBirad}
                                >
                                    {this.state.availableBirads.map((b, idx) => <Option key={idx} key={b.birads} value={b.birads}>{b.birads}</Option>)}
                                    <Option key="No Result Entered" value="No Result Entered">No Result Entered</Option>
                                </Select>
                            </div>

                            <div className="form-group col col-lg-6 col-12">
                                <div className="form-label">Exam Types</div>
                                <Select
                                    mode="multiple"
                                    maxTagCount={1}
                                    allowClear
                                    placeholder="Please select"
                                    onChange={this.handleExamTypesChange}
                                    className="form-control-custom w-100"
                                    bordered={false}
                                    virtual={false}
                                    value={this.state.selectedExamTypes}
                                >
                                    {this.state.availableExamTypes.map((e, idx) => <Option key={idx} key={e.examTypeID} value={e.examTypeID}>{e.examType}</Option>)}
                                </Select>
                            </div>

                            <div className="form-group col-lg-6">
                                <label className="form-label">Show Detailed Report</label>
                                <div className="form-control-custom no-border">
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" className="custom-control-input" id="showDetails" name="showDetails" value={this.state.showDetails || ''} onChange={this.handleInputChange} />
                                        <label className="custom-control-label"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                            


                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-submit float-left" onClick={this.handleSubmit} style={{ left: '10px', position: 'fixed', backgroundColor: '#1D6F42', borderColor: '#1D6F42' }}>Export to PDF</button>
                    <div className="row">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-submit" onClick={this.exportExcel}>Run Report</button>
                            <button className="btn btn-outline-default margin-left-15" onClick={this.handleClear}>Clear</button>
                            <button className="btn btn-outline-default margin-left-15" id="closeModal" onClick={() => this.props.hideModal()}>Cancel</button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withRouter(RadDetailsReportModal);