import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';

import MaskedInput from 'react-text-mask'

import { FaAngleDown } from 'react-icons/fa';
import $ from 'jquery';
import { Dropdown } from 'react-bootstrap';

import Axios from '../../config/axios';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';

import Globals from '../../config/globals';

import { getPatientsByLookup, patientResults, loadingPatientResults, allRecallTypes, userAssociatedFacilities, allInsuranceCompanies } from '../../common/services/ApiService';

import { Select } from 'antd';

import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { useRecoilState } from 'recoil';
import { patientFilterString } from '../../common/services/PatientService';

function PatientSearchForm(props) {

    const alive = useRef(false);
    const wrapperRef = useRef(null);

    const [state, setState] = useState({
        formFields: {
            lName: '',
            fName: '',
            dob: '',
            mrn: '',
            referringMD: '',
            startDate: null,
            endDate: null,
            selectedRecallType: '',
            primaryRecallTypeID: '',
        },
        recallTypesList: [],
        facilityList: [],
        issuersList: [],
        selectedFacilities: [],
        selectedIssuers: [],
        insuranceCompanyList: [],
        selectedInsurance: [],
        regexArray: [/^.$/],
        noDropdown: props.from === 'nav' ? false : true,
        pasted: true
    });

    const [issuersList, setIssuersList] = useState([]);
    const [recallTypeList, setRecallTypeList] = useState([]);

    const [filterString, setFilterString] = useRecoilState(patientFilterString);

    useEffect(() => {
        alive.current = true;
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('paste', handlePasteAnywhere);

        combineLatest(
            allRecallTypes,
            userAssociatedFacilities,
            allInsuranceCompanies
        ).pipe(takeWhile(() => alive)).subscribe(([x1, x2, x3]) => {

            setRecallTypeList(x1);

            setState({
                ...state,
                recallTypesList: x1,
                listsLoaded: true,
                facilityList: x2.filter(item => item.facilityModel.inactiveYN !== 1),
                insuranceCompanyList: x3
            })
        });

        getIssuers();

        return () => {
            alive.current = false;
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('paste', handlePasteAnywhere);
        };
    }, []);

    function handlePasteAnywhere(event) {
        let text = event.clipboardData?.getData('Text');

        //let value = this.state.formFields?.value ? this.state.formFields.value + text : text;

        let regexArray = state.regexArray;

        for (var i = 0; i < text.length; i++) {
            regexArray.push(/^.$/);
        }

        setState({
            ...state,
            regexArray
        });
    }

    /**
     * Set the wrapper ref
     */
    function setWrapperRef(node) {
        if(wrapperRef) wrapperRef.current = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
        var isAntItem = $(event.target).hasClass('ant-select-item-option-content');

        if (state.showDropdown && wrapperRef && !wrapperRef?.current.contains(event.target) && !isAntItem) {
            toggleDropdown();
        }
    }

    function toggleDropdown() {
        if (state.showDropdown) {
            setState({
                ...state,
                showDropdown: false,
                formFields: {
                    lName: '',
                    fName: '',
                    dob: '',
                    mrn: '',
                    referringMD: '',
                    startDate: null,
                    endDate: null,
                    issuer: ''
                }
            });
        } else {
            setState({ ...state, showDropdown: true });
        }
    }

    //function getRecallTypes() {
    //    Axios.get(`/api/GetAllRecallTypes`
    //    ).then(response => {
    //        let recallTypesList = response.data;
    //        setState({
    //            ...state,
    //            recallTypesList
    //        });
    //    }).catch(error => {
    //        console.log(error);
    //    });
    //}

    function getIssuers() {
        Axios.get(`/api/GetAllIssuers`
        ).then(response => {
            setIssuersList(response.data);
        }).catch(error => {
            console.log(error);
        });
    }

    function handleInputChange(event) {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let regexArray = [];

        if (typeof value !== "boolean") {
            const val = value?.split('_');

            var full_date_regex = /^[0-9]{2}[\/][0-9]?[0-9]?[\/]?[0-9]?[0-9]?[0-9]?$[0-9]?/;
            var regexMatched = full_date_regex.test(val[0]);

            var exact_date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]?|1\d?|2\d?|3[01]?)\/(19|20)\d{2}$/;


            if (name === 'lName') {
                if (regexMatched || exact_date_regex.test(val[0])) {
                    var regexToAddSlash = /^[0-9]{2}[\/][0-9][0-9]$/;
                    if (regexToAddSlash.test(val[0])) {
                        value = value + '/';
                    }
                    regexArray = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
                } else {
                    let newReg = /^.$/;

                    for (var i = 0; i < val[0].length + 1; i++) {
                        regexArray.push(newReg);
                    }
                }
            }
        }
        

        setState({ ...state, formFields: { ...state.formFields, [name]: value }, regexArray });
    }

    //function determineRegex(value) {
    //    var date1 = /^(0[1-9]|1[0-2])\/$/;
    //    var date2 = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

    //    switch (value) {
    //        case date1.test(value):
    //            return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    //        default:
    //            let newReg = /^.$/;
    //            let regexArray = [];
    //            const val = value.split('_');
    //            for (var i = 0; i < val[0].length + 1; i++) {
    //                regexArray.push(newReg);
    //            }
    //            return regexArray;
    //    }
    //}

    function handleDateChange(name, date) {
        setState({ ...state, formFields: { ...state.formFields, [name]: date } });
    }

    //function handleDropdownChange(value) {
    //    setState({
    //        ...state,
    //        value
    //    });
    //}

    function handleSubmit(event, searchType) {
        event.preventDefault();

        $('.form-control-custom').blur();

        //this.inputRef.current.blur();

        //Globals.loadingPatients = true;
        //this.props.updatePatientsLoading();

        loadingPatientResults.next(true);

        let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

        let lastName = state.formFields.lName;
        if (lastName.endsWith("_")) {
            lastName = lastName.replaceAll("_", "");
        }

        let ff = '';
        let filterFields = { ...state.formFields };

        for (const property in filterFields) {
            if (filterFields[property]) {
                if ((Array.isArray(filterFields[property]) && filterFields[property].length > 0)) {
                    ff += `&filters=${property}:in:${filterFields[property]}`;
                } else if (!Array.isArray(filterFields[property])) {
                    if (property === 'dob' || property === 'startDate' || property === 'endDate') {
                        ff += `&filters=${property}:eq:${filterFields[property].toLocaleDateString()}`;
                    } else {
                        ff += `&filters=${property}:cn:${filterFields[property]}`;
                    }
                }
            }
        }

        if (state.selectedFacilities?.length > 0) {
            ff += `&filters=facilityID:in:'${state.selectedFacilities.join("','")}'`;
        }

        if (state.selectedIssuers?.length > 0) {
            ff += `&filters=issuerofPatientID:in:'${state.selectedIssuers.join("','")}'`;
        }

        if (state.selectedInsurance?.length > 0) {
            ff += `&filters=insuranceID:in:'${state.selectedInsurance.join("','")}'`;
        }

        setFilterString(ff);

        let currentRoute = props.history.location.pathname;

        if (currentRoute === '/patients') {
            props.history.replace({
                pathname: '/patients'
            });
        }
        else {
            props.history.push({
                pathname: '/patients'
            });
        }
        setState({
            ...state,
            formFields: {
                fName: '',
                lName: '',
                dob: '',
                mrn: ''
            },
            selectedFacilities: [],
            selectedInsurance: [],
            selectedIssuers: []
        });
        
        $(".search-menu").removeClass("show");
        $(".search-menu-left").removeClass("show");

        //let postdata = {
        //    patientModel: {
        //        fName: state.formFields.fName,
        //        lName: lastName,
        //        dob: state.formFields.dob,
        //        mrn: state.formFields.mrn
        //    },
        //    facilityList: state.selectedFacilities,
        //    recallStartDate: state.formFields.startDate,
        //    recallEndDate: state.formFields.endDate,
        //    issuerList: state.selectedIssuers,
        //    insuranceList: state.selectedInsurance,
        //    primaryRecallTypeID: state.formFields.primaryRecallTypeID !== '' ? state.formFields.primaryRecallTypeID : null,
        //    searchType: event.target.id,
        //    userID: userInfo.userId,
        //    showInactivePatients: state.formFields.showInactivePatients
        //}

        //Globals.patientSearchCriteria = JSON.stringify(postdata);

        //Globals.searchCriteria = postdata;
        ////Globals.patients = [];

        //$(".search-menu").removeClass("show");
        //$(".search-menu-left").removeClass("show");

        //getPatientsByLookup(postdata).then(data => {
        //    //if (data?.length > 0) {
        //    //    this.setState({
        //    //        formFields: {
        //    //            fName: '',
        //    //            lName: '',
        //    //            dob: '',
        //    //            mrn: ''
        //    //        }
        //    //    });
        //    //}

        //    Globals.searchDirty = true;

        //    patientResults.next(data);
        //    loadingPatientResults.next(false);
        //});
    }

    //function onSelect(selectedFacilities, facility) {
    //    setState({
    //        ...state,
    //        selectedFacilities
    //    });
    //}

    //function onRemove(selectedFacilities, facility) {
    //    setState({
    //        ...state,
    //        selectedFacilities
    //    });
    //}

    //function onSelectIssuer(selectedIssuers) {
    //    setState({
    //        ...state,
    //        selectedIssuers
    //    });
    //}

    //function onRemoveIssuer(selectedIssuers) {
    //    setState({
    //        ...state,
    //        selectedIssuers
    //    });
    //}

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <span href=""
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }} className="inline-button" id="dropdownMenuOffset" data-offset="0,10">

            <FaAngleDown />

        </span>
    ));
    const { Option } = Select;

    return (
        <div>
            {state.noDropdown ?
                <form onSubmit={(e) => handleSubmit(e, 'detailed')}>
                    <div className="row">
                        <div className="form-group col-lg-2 col-12">
                            <label className="form-label">Last Name</label>
                            <input type="text" name="lName" value={state.formFields.lName || ''} onChange={handleInputChange} className="form-control-custom" />
                        </div>
                        <div className="form-group col-lg-2 col-12">
                            <label className="form-label">First Name</label>
                            <input type="text" name="fName" value={state.formFields.fName || ''} onChange={handleInputChange} className="form-control-custom" />
                        </div>
                        <div className="form-group col-lg-2 col-12">
                            <label className="form-label">Patient ID</label>
                            <input type="text" name="mrn" value={state.formFields.mrn || ''} onChange={handleInputChange} className="form-control-custom" />
                        </div>
                        <div className="form-group col-lg-2 col-12">
                            <label className="form-label">DOB</label>
                            <DatePicker
                                onChange={(date) => handleDateChange('dob', date)}
                                className="form-control-custom"
                                selected={state.formFields.dob && Moment(state.formFields.dob).toDate()}
                                customInput={
                                    <MaskedInput
                                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                        keepCharPositions={true}
                                        guide={true}
                                    />

                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </div>
                        <div className="form-group col-lg-2 col-12 mt-4">
                            <button className="btn btn-submit" type="submit" onClick={handleSubmit}>Search</button>
                        </div>
                    </div>
                </form>
                :
                <Dropdown className="search-div" show={state.showDropdown} ref={setWrapperRef}>


                    <form onSubmit={(e) => handleSubmit(e, 'quick')} id="quick">
                        <MaskedInput
                            mask={state.regexArray}
                            keepCharPositions={true}
                            guide={true}
                            showMask={false}
                            type="text" id="" name="lName" placeholder="Search for a patient" value={state.formFields.lName} onChange={handleInputChange} className="form-control-custom"
                        />

                    </form>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" onClick={() => toggleDropdown()}
                        onBlur={() => toggleDropdown()}>
                        Custom toggle
                    </Dropdown.Toggle>
                    <span onSubmit={(e) => handleSubmit(e, 'quick')} title="Search" className="search-inline-button" id="quick">
                        <i className="fa fa-search" id="quick" />
                    </span>

                    <Dropdown.Menu className={"dropdown-menu " + (props.from === 'nav' ? 'search-menu' : 'search-menu-left')} aria-labelledby="dropdownMenuOffset">
                        <div className="panel-container show">
                            <div className="panel-content">
                                <form onSubmit={(e) => handleSubmit(e, 'detailed')} id="detailed">

                                    <div className="row">
                                        <div className="col col-md-6 form-group">
                                            <label className="form-label">Last Name</label>
                                            <input type="text" id="" name="lName" value={state.formFields.lName} onChange={handleInputChange} className="form-control-custom" />
                                        </div>

                                        <div className="col col-md-6 form-group">
                                            <label className="form-label">First Name</label>
                                            <input type="text" name="fName" value={state.formFields.fName} onChange={handleInputChange} className="form-control-custom" />
                                        </div>

                                        <div className="col col-md-6 form-group">
                                            <label className="form-label">MRN</label>
                                            <input type="text" name="mrn" value={state.formFields.mrn} onChange={handleInputChange} className="form-control-custom" />
                                        </div>

                                        <div className="form-group col-lg-6">
                                            <label className="form-label">DOB</label>
                                            <DatePicker
                                                onChange={(date) => handleDateChange('dob', date)}
                                                className="form-control-custom"
                                                selected={state.formFields.dob && Moment(state.formFields.dob).toDate()}
                                                customInput={
                                                    <MaskedInput
                                                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                        keepCharPositions={true}
                                                        guide={true}
                                                    />

                                                }
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                            />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label className="form-label">Facility</label>
                                            {state.facilityList.length === 1 ?
                                                <div>{state.facilityList[0].facilityModel.facilityName}</div>
                                                :
                                                <Select
                                                    mode="multiple"
                                                    maxTagCount={1}
                                                    onChange={(selectedFacilities) => setState({ ...state, selectedFacilities })}
                                                    allowClear
                                                    placeholder="Please select 1 or more"
                                                    className="form-control-custom w-100"
                                                    bordered={false}
                                                    value={state.selectedFacilities}
                                                    showSearch
                                                    virtual={false}
                                                    filterOption={(input, option) => (option.children[0] + option.children[1]).toLowerCase().includes(input.toLowerCase())}
                                                >
                                                    {state.facilityList.map((fac, idx) => <Option key={idx} value={fac.facilityModel.facilityID}>{fac.facilityModel.facilityNumber && fac.facilityModel.facilityNumber + " - "}{fac.facilityModel.facilityName}</Option>)}
                                                </Select>
                                            }


                                        </div>

                                        <div className="form-group col-lg-6">
                                            <label className="form-label">Issuer</label>
                                            <Select
                                                mode="multiple"
                                                maxTagCount={1}
                                                onChange={(selectedIssuers) => setState({ ...state, selectedIssuers })}
                                                allowClear
                                                placeholder="Please select 1 or more"
                                                className="form-control-custom w-100"
                                                bordered={false}
                                                value={state.selectedIssuers}
                                                showSearch
                                                virtual={false}
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {issuersList.map((i, idx) => <Option key={idx} value={i.issuerofPatientID}>{i.issuerName}</Option>)}
                                            </Select>

                                        </div>

                                        <div className="form-group col-lg-6">
                                            <label className="form-label">Recall Type</label>
                                            <select className="form-control-custom" value={state.formFields.primaryRecallTypeID} name="primaryRecallTypeID" onChange={handleInputChange}>
                                                <option></option>
                                                {recallTypeList.map((rt, idx) => <option key={idx} value={rt.recallTypeID}>{rt.recallTypeDescription}</option>)}
                                            </select>
                                        </div>


                                        <div className="form-group col-lg-6">
                                            <label className="form-label">Insurance Companies</label>

                                            <Select
                                                mode="multiple"
                                                maxTagCount={1}
                                                onChange={(selectedInsurance) => setState({ ...state, selectedInsurance })}
                                                allowClear
                                                placeholder="Please select 1 or more"
                                                className="form-control-custom w-100"
                                                bordered={false}
                                                virtual={false}
                                                value={state.selectedInsurance}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {state.insuranceCompanyList.map((i, idx) => <Option key={idx} value={i.insuranceID}>{i.insCompName}</Option>)}
                                            </Select>

                                        </div>

                                        <div className="form-group col-lg-6">
                                            <label className="form-label">Show Inactive Patients</label>
                                            <div className="form-control-custom no-border">
                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" className="custom-control-input" id="showInactivePatients" name="showInactivePatients" value={state.formFields.showInactivePatients || ''} onChange={handleInputChange} />
                                                    <label className="custom-control-label"></label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group col-lg-6">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label className="form-label">Recall Date</label>
                                                    <DatePicker
                                                        onChange={(date) => handleDateChange('startDate', date)}
                                                        selectsStart
                                                        startDate={state.formFields.startDate}
                                                        endDate={state.formFields.endDate}
                                                        className="form-control-custom"
                                                        selected={state.formFields.startDate && Moment(state.formFields.startDate).toDate()}
                                                        placeholderText='Start Date'
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        customInput={
                                                            <MaskedInput
                                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                                keepCharPositions={true}
                                                                guide={true}
                                                            />
                                                        }
                                                    />
                                                </div>
                                                <div className="col-12 mt-3">
                                                    <DatePicker
                                                        onChange={(date) => handleDateChange('endDate', date)}
                                                        selectsEnd
                                                        startDate={state.formFields.startDate}
                                                        endDate={state.formFields.endDate}
                                                        minDate={state.formFields.startDate}
                                                        className="form-control-custom"
                                                        selected={state.formFields.endDate && Moment(state.formFields.endDate).toDate()}
                                                        placeholderText='End Date'
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        customInput={
                                                            <MaskedInput
                                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                                keepCharPositions={true}
                                                                guide={true}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div className="modal-footer">
                                        <div className="form-group col-12 padding-25-10">
                                            <button className="btn btn-submit" type="submit" onClick={handleSubmit}>Search</button>
                                            <button className="btn btn-outline-default margin-left-15" type="button" onClick={toggleDropdown}>Cancel</button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>

                    </Dropdown.Menu>
                </Dropdown>
            }
        </div>
    );
    
}

export default withRouter(PatientSearchForm);