import { atom } from 'recoil';

export const patientTableParams = atom({
    key: 'patientTableParams',
    default: {
        pagination: {
            current: 1,
            pageSize: 50,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            showSizeChanger: true
        },
    }
});

export const patientFilterString = atom({
    key: 'patientFilterString', 
    default: ''
})