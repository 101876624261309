import Axios from '../../config/axios';
import { BehaviorSubject } from 'rxjs';

export const reportComponents = new BehaviorSubject([]);
export const reportComponentTypes = new BehaviorSubject([]);
export const reportTemplates = new BehaviorSubject([]);
export const reportDefinitionTypes = new BehaviorSubject([]);
export const reportDefinitionIcons = new BehaviorSubject([]);
export const allFacilities = new BehaviorSubject([]);
export const allUsers = new BehaviorSubject([]);
export const allIssuers = new BehaviorSubject([]);

export const reportDefinitions = new BehaviorSubject({});
export const reportComponentsSplit = new BehaviorSubject({});
export const definitionsByType = new BehaviorSubject({});

export function getAllComponents() {
    Axios.get(`/api/GetAllComponents`).then(response => {
        reportComponents.next(response.data);
    }).catch(error => {
        console.log(error);
    });
}

export function getAllComponentTypes() {
    Axios.get(`/api/GetAllComponentTypes`).then(response => {
        reportComponentTypes.next(response.data);
    }).catch(error => {
        console.log(error);
    });
}

export function getAllTemplates() {
    Axios.get(`/api/GetAllTemplates`).then(response => {
        reportTemplates.next(response.data);
    }).catch(error => {
        console.log(error);
    });
}

export function getReportDefinitionPieces() {
    Axios.get(`/api/GetReportDefinitionPieces`).then(response => {
        reportComponents.next(response.data.reportComponents);
        reportTemplates.next(response.data.reportTemplates);
        reportDefinitions.next(response.data.reportDefinitions);
        reportComponentTypes.next(response.data.reportComponentTypes);
        reportComponentsSplit.next(response.data.reportComponentsSplit);
        reportDefinitionTypes.next(response.data.reportDefinitionTypes);
    }).catch(error => {
        console.log(error);
    });
}

export function getAdminItems() {
    Axios.get(`/api/GetAdminItems`).then(response => {

        reportComponentTypes.next(response.data.reportComponentTypes);
        reportDefinitionTypes.next(response.data.reportDefinitionTypes);
        reportDefinitionIcons.next(response.data.reportDefinitionIcons);
        definitionsByType.next(response.data.definitionsByType);
        allFacilities.next(response.data.allFacilities);
        allUsers.next(response.data.allUsers);
        allIssuers.next(response.data.allIssuers);
    }).catch(error => {
        console.log(error);
    });
}

export function getReportDefinitionTypes() {
    Axios.get('/api/GetAllDefinitionTypes').then(response => {
        reportDefinitionTypes.next(response.data);
    }).catch(error => {
        console.log(error);
    });
}

export function insertComponent(component) {
    return Axios.post(`/api/InsertComponent`, component
    ).then(response => response.data);
}

export function updateComponent(component) {
    return Axios.post(`/api/UpdateComponent`, component
    ).then(response => response.data);
}

export function deleteComponent(component) {
    return Axios.post(`/api/DeleteComponent`, component
    ).then(response => response.data);
}

export function insertTemplate(template) {
    return Axios.post(`/api/InsertTemplate`, template
    ).then(response => response.data);
}

export function updateTemplate(template) {
    return Axios.post(`/api/UpdateTemplate`, template
    ).then(response => response.data);
}

export function deleteTemplate(template) {
    return Axios.post(`/api/DeleteTemplate`, template
    ).then(response => response.data);
}

export function insertReportDefinition(postdata) {
    return Axios.post(`/api/InsertReportDefinition`, postdata
    ).then(response => response.data);
}

export function updateReportDefinition(postdata) {
    return Axios.post(`/api/UpdateReportDefinition`, postdata
    ).then(response => response.data);
}

export function deleteReportDefinition(postdata) {
    return Axios.post(`/api/DeleteReportDefinition`, postdata
    ).then(response => response.data);
}

export function insertDefinitionType(type) {
    let postdata = {
        definitionTypeName: type
    };

    return Axios.post(`/api/InsertDefinitionType`, postdata
    ).then(response => response.data);
}

export function updateDefinitionType(postdata) {
    return Axios.post(`/api/UpdateDefinitionType`, postdata
    ).then(response => response.data);
}

export function deleteDefinitionType(postdata) {
    return Axios.post(`/api/DeleteDefinitionType`, postdata
    ).then(response => response.data);
}

export function getReportDefinitionMasterByDefinitionType(reportDefinitionTypesID) {
    return Axios.get('/api/GetReportDefinitionMasterByDefinitionType', {
        params: {
            reportDefinitionTypesID
        }
    }).then(response => response.data);
}

export function insertComponentType(type) {
    let postdata = {
        componentTypeName: type
    };

    return Axios.post(`/api/InsertComponentType`, postdata
    ).then(response => response.data);
}

export function updateComponentType(postdata) {
    return Axios.post(`/api/UpdateComponentType`, postdata
    ).then(response => response.data);
}

export function deleteComponentType(postdata) {
    return Axios.post(`/api/DeleteComponentType`, postdata
    ).then(response => response.data);
}

export function getReportComponentsByComponentType(reportComponentTypesID) {
    return Axios.get('/api/GetReportComponentsByComponentType', {
        params: {
            reportComponentTypesID
        }
    }).then(response => response.data);
}

export function getDefinitionIconsByFacility(facilityID) {
    return Axios.get('/api/GetDefinitionIconsByFacility', {
        params: {
            facilityID
        }
    }).then(response => {
        reportDefinitionIcons.next(response.data);
    }).catch(error => {
        console.log(error);
    });
}

export function updateDefinitionIconsByFacility(postdata) {
    return Axios.post(`/api/UpdateDefinitionIconsByFacility`, postdata
    ).then(response => response.data);
}

export function insertDefinitionIconByFacility(postdata) {
    return Axios.post(`/api/InsertDefinitionIconByFacility`, postdata
    ).then(response => response.data);
}

export function deleteDefinitionIcon(postdata) {
    return Axios.post(`/api/DeleteDefinitionIcon`, postdata
    ).then(response => response.data);
}

export function resetDefinitionIconsByFacility(postdata) {
    return Axios.post(`/api/ResetDefinitionIconsByFacility`, postdata
    ).then(response => response.data);
}

export function downloadPreviousReport(id, prefix = null) {
    return Axios.get(`/api/DownloadPreviousReport`, {
        params: {
            id,
            prefix
        },
        responseType: 'blob'
    }).then(response => response.data);
}

export function getPrintHistoryByIcon(id, patientID) {
    return Axios.get(`/api/GetPrintHistoryByIcon`, {
        params: {
            id,
            patientID
        }
    }).then(response => response.data);
}

export function getPrintHistoryByEntityID(entityID) {
    return Axios.get('/api/GetPrintHistoryByEntityID', {
        params: {
            entityID
        }
    }).then(response => response.data);
}

export function renderOpenExamReport() {
    return Axios.get(`/api/RenderOpenExamReport`).then(response => response.data);
}

export function handlePrintHx(patientID) {

    const q = Axios.defaults.baseURL + "api/RenderHxSheet";

    var form = document.createElement("form");
    form.target = "_blank";
    form.method = "POST";
    form.action = q;
    form.style.display = "none";

    var input = document.createElement("input");
    input.type = "hidden";
    input.name = "patientID";
    input.value = patientID;
    form.appendChild(input);

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
}

export function updatePrintHistoryItem(postdata) {
    return Axios.post('/api/UpdatePrintHistoryItem', postdata).then(response => response.data).catch(error => error);
}

export function getFacilityAndGlobalNonCompliantLetters(facilityID) {
    return Axios.get('/api/GetFacilityAndGlobalNonCompliantLetters', {
        params: {
            facilityID
        }
    }).then(response => response.data).catch(error => error);
}

export function getRadsMappedToFacilityExams(facilityID) {
    return Axios.get('/api/GetRadsMappedToFacilityExams', {
        params: {
            facilityID
        }
    }).then(response => response.data).catch(error => error);
}

export function getTechsMappedToFacilityExams(facilityID) {
    return Axios.get('/api/GetTechsMappedToFacilityExams', {
        params: {
            facilityID
        }
    }).then(response => response.data).catch(error => error);
}